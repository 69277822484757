import * as React from "react";
import { forwardRef } from "react";
const SvgLogoMark = (props, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "48px", height: "48px", style: {
  shapeRendering: "geometricPrecision",
  textRendering: "geometricPrecision",
  imageRendering: "optimizeQuality",
  fillRule: "evenodd",
  clipRule: "evenodd"
}, xmlnsXlink: "http://www.w3.org/1999/xlink", ref, ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 1
}, fill: "#3677e4", d: "M 28.5,19.5 C 25.131,17.4893 21.4643,16.4893 17.5,16.5C 11.9024,16.3954 7.23575,18.3954 3.5,22.5C 2.59235,21.21 2.75902,19.8767 4,18.5C 11.6586,10.9667 20.8252,8.46672 31.5,11C 34.7789,11.9728 37.7789,13.4728 40.5,15.5C 40,15.8333 39.5,16.1667 39,16.5C 34.6446,14.6827 30.1446,14.1827 25.5,15C 27.1412,16.129 28.1412,17.629 28.5,19.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 1
}, fill: "#39e5e4", d: "M 17.5,16.5 C 21.4643,16.4893 25.131,17.4893 28.5,19.5C 29.7828,22.3654 29.7828,25.3654 28.5,28.5C 29.325,29.3857 30.325,29.719 31.5,29.5C 30.4324,30.4345 29.099,30.7678 27.5,30.5C 22.3983,30.5813 18.065,28.9147 14.5,25.5C 14.6469,22.2084 15.6469,19.2084 17.5,16.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 1
}, fill: "#3175e2", d: "M 14.5,25.5 C 18.065,28.9147 22.3983,30.5813 27.5,30.5C 31.4405,31.5384 35.4405,32.5384 39.5,33.5C 31.5111,38.6411 22.8444,39.8078 13.5,37C 10.3975,35.7193 7.73081,33.8859 5.5,31.5C 5.83333,31.1667 6.16667,30.8333 6.5,30.5C 10.0982,33.1346 14.0982,33.9679 18.5,33C 16.2589,31.2609 14.5922,29.0942 13.5,26.5C 13.6236,25.8933 13.9569,25.56 14.5,25.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 1
}, fill: "#3a77e1", d: "M 39.5,33.5 C 35.4405,32.5384 31.4405,31.5384 27.5,30.5C 29.099,30.7678 30.4324,30.4345 31.5,29.5C 36.9541,29.7987 41.2874,27.7987 44.5,23.5C 45.0498,27.736 43.3831,31.0693 39.5,33.5 Z" })));
const ForwardRef = forwardRef(SvgLogoMark);
export default ForwardRef;
