import * as React from "react";
import { forwardRef } from "react";
const SvgLogoType = (props, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "160px", height: "22px", style: {
  shapeRendering: "geometricPrecision",
  textRendering: "geometricPrecision",
  imageRendering: "optimizeQuality",
  fillRule: "evenodd",
  clipRule: "evenodd"
}, xmlnsXlink: "http://www.w3.org/1999/xlink", ref, ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.435
}, fill: "#fefefe", d: "M 0.5,0.5 C 2.61381,1.36018 3.78048,3.02685 4,5.5C 4.73926,3.77948 5.5726,2.11282 6.5,0.5C 7.13729,3.63524 6.47062,6.63524 4.5,9.5C 2.37792,6.91674 1.04459,3.91674 0.5,0.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.598
}, fill: "#fefefe", d: "M 8.5,0.5 C 9.37644,0.369118 10.0431,0.702451 10.5,1.5C 9.7695,4.10919 9.2695,6.77586 9,9.5C 8.50204,6.51838 8.33537,3.51838 8.5,0.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.447
}, fill: "#fefefe", d: "M 16.5,0.5 C 17.29,1.60853 17.79,2.94186 18,4.5C 19.594,3.28407 21.094,1.95074 22.5,0.5C 23.4841,3.42814 23.8174,6.42814 23.5,9.5C 21.2776,9.82202 19.4443,9.15536 18,7.5C 17.6667,8.16667 17.3333,8.83333 17,9.5C 16.502,6.51838 16.3354,3.51838 16.5,0.5 Z M 20.5,4.5 C 21.5735,5.25057 21.7401,6.25057 21,7.5C 19.2716,6.64767 19.105,5.64767 20.5,4.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.487
}, fill: "#fefefe", d: "M 12.5,2.5 C 16.2887,3.59607 16.2887,4.26273 12.5,4.5C 12.5,5.5 12.5,6.5 12.5,7.5C 15.7973,7.3287 16.1306,7.99536 13.5,9.5C 9.96673,7.57981 9.6334,5.24648 12.5,2.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.472
}, fill: "#fefefe", d: "M 25.5,2.5 C 28.179,2.52462 29.5123,3.85795 29.5,6.5C 25.551,6.69322 25.551,7.19322 29.5,8C 28.4071,9.36537 27.0738,9.6987 25.5,9C 24.232,6.83683 24.232,4.67016 25.5,2.5 Z" })));
const ForwardRef = forwardRef(SvgLogoType);
export default ForwardRef;
